*{
  box-sizing: border-box;
}

.layer-4{
    display: flex;
    max-width: 1085px;
    height: auto;
    width: 100%;
    margin-left:auto;
    margin-right: auto;
    margin-top: 7vw;
    margin-bottom: 5vw;
    align-items: flex-start;
    justify-content: center; /* Center horizontally */
    gap: 8%;
}

.Line3{
  max-width:100%;
  width: 100%;
  height: auto;
  top:1px;
  margin-left: 110%;
  position: absolute;
  transform: rotate(90deg);
  transform-origin: 0 0;
  border: 1px #ED8D8D solid;
  background-image: linear-gradient(to right, rgba(237, 141, 141, 0), rgba(237, 141, 141, 0.3), #ED8D8D, rgba(237, 141, 141, 0.3), rgba(237, 141, 141, 0));
}

.Events {
    max-width: 534px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  
  .Event-header {
    text-align: center;
    color: #1D1753;
    font-size: 4vw;
    font-family: 'Reem Kufi Fun';
    font-weight: 600;
    letter-spacing: 1.04px;
    word-wrap: break-word;
  }
  /*

  .image-carousel{
    width: 534px;
    height: 532;
    left: 3;
    top: 12px;
    position: absolute;
  }
.Frame8Varriant2{
  height: 500px;
    padding-left: 30px;
    left: 14px;
    top:100px;
    position: absolute;
    display: grid;
  justify-content: center;
  gap: 64px;
    

}*/
  
  .Frame30{
    display: flex;
    
  }
  
  .mySlides {
    max-width: 500px;
    width: 100%;
    padding:2vw 6vw 2vw 6vw; 

    position: relative;
}
  
  .slide-carousel {
    width: 370px;
    height: auto;
  }
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 300px;
    transform: translateY(-50%);
    color: rgb(235, 101, 101);
    font-size: 3vw;
    transition: 0.6s ease;
    border: none;
    background-color: transparent;
    user-select: none;

  }
  .prev{
    left: 0vw;
  }
  .next {
    right: -1vw;
  }
   
  
  .text {
    max-width: 90%;
    width: 100%;
    margin: 1.5vw auto;
    text-align: justify;
    color: #cd5c5c;
    font-size: 2.4vw;
    text-align: center;
    font-family: 'poppins';
    letter-spacing: 0.03em;
    font-weight: 600;
  }
  

.announcement-career{
  max-width: 90%;
  width: 100%;
  height: auto;
  flex-direction: column;
  gap: 5%;
  display: flex;
  position: relative;
}

.announcement{
  width: 100%;
  height: 20vw;
  position:relative;
  background-color: #FFF1F1;
  border-radius: 3vw;
  overflow-y:auto;
  margin-bottom: 5%;
}

.announcement::-webkit-scrollbar{
  display: none;
}

.rectangle35{
  position: absolute;
}

.text-arrow{
  margin-top: 5vw;
}

.Line2{
  max-width: 110%;
  width:100% ; 
  height: 0vw;
   border: 1px #F9D4D4 solid;
}

.New{
  color: #FF6060; 
  font-size: 0.95vw;
  font-family: 'Poppins';
  font-weight: bold; 
  line-height: 2.5;
  letter-spacing: 0.05em; 

}
.career{
  width: 100%;
  height: 19vw;
  position: relative;
  background-color: #F1F4FF;
  border-radius: 3vw; 
  overflow-y: auto;
}

.career::-webkit-scrollbar{
  display: none;
}

.rectangle36{
  position: absolute;
}
.arrow-image{
  padding: 0vw 1vw 0px 1.5vw;
  align-self: start;

}
.arrow-image img{
  height: 2vw;
  width: 1.5vw;
  padding-top: 0.5vw;
}

.frame46{
  max-width:90%;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}


.text-ann{

  width: calc(100% - 3vw);
  color: #651313;
  font-family: 'Poppins'; 
  font-weight: bold; /* Removed quotes */
  line-height: 1.5; /* Added 'px' unit */
  letter-spacing: 0.44px; /* Added 'px' unit */
  word-wrap: break-word;
  font-size: 1.25vw;
}

@media only screen and (max-width: 450px) {
  .Event-header {
    font-size: 6vw; /* Adjusted font size for smaller screens */
  }
  
  .prev, .next {
    font-size: 5vw; /* Adjusted font size for smaller screens */
    top:200px;
    margin: 20px;
  }

  .text {
    font-size: 4vw; /* Adjusted font size for smaller screens */
  }

  .New, .arrow-image img {
    font-size: 2vw; /* Adjusted font size for smaller screens */
    height: 3vw; /* Adjusted height for smaller screens */
    width: 2.5vw; /* Adjusted width for smaller screens */
  }

  .Line2 {
    height: 0.3vw; /* Adjusted height for smaller screens */
  }

  .text-ann, .text-ann-1 {
    font-size: 2vw; /* Adjusted font size for smaller screens */
  }

  .layer-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* Add margin for spacing */
  }
  .Line3{
    display: none;

  }
  .Event-header {
    max-width: 1085px;
    width: 100%; /* Adjust to fit smaller screens */
    font-size: 7vw; /* Adjust font size for smaller screens */
    text-align: center; /* Center the text horizontally */
    margin-bottom: 3vw; /* Adjust margin for spacing */
}
  .announcement-career{
    max-width: 100%;
    width: 100%;
    flex-direction: row;
    gap: 5%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: relative;
    margin-top: 30px;
  }
  .announcement{
    height: 180px;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  .announcement-header{
    max-width: 50%;
    width: 100%;
    margin-right: 30px;
  }
  .career{
    height: 180px;
    box-sizing: border-box;
  }

  .text-ann {
  width: 180px;
    font-size: 2vw; /* Adjust font size for smaller screens */
  }
  .New {
    width: 20px;
    font-size: 1.5vw; /* Adjust margin for spacing */
  }

  .frame46{
    max-width:45%;
    width: 100%;
  }
  .Line2{
    height: 0vw;
    max-width: 50%;
  }

  .mySlides {
    max-width: 400px; /* Adjusted max-width for smaller screens */
    padding: 4vw; /* Adjusted padding for smaller screens */
  }

  .slide-carousel {
    width: 250px; /* Adjusted width for smaller screens */
    /* margin-left: 80px; */
    margin: auto;
  }

  
}
