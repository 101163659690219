.GalleryMainImg {
    background: url('../Images/IMAGE.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 529px;
    width: 1408px;
    margin-top: 52px;
    margin-left: 44px;
}

.Galleryfirst {
    background: url('../Images/gallery2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 570px;
    width: 570px;
    margin-top: 0px;
    /* margin-left: 44px; */
}

.mainimgdiv {
    width: 980px;
    height: 623px;
    display: flex;
}

.imgbackground {
    /* margin-left: 89.96px; */
    height: 623px;
    width: 815.35px;
    background-color: white;
    border-radius: 27px;
}

.GallerySecondSectionimages {
    height: 623px;
    width: 800.35px;
}

.GallerySecondSection {
    margin-top: 50px;
    margin-left: 83px;
    height: 1793px;
    width: 1318px;
    padding-left: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.flex-item {
    flex-shrink: 1;
    /* Each div grows to fill available space equally */
    min-width: 0;
    /* Allow items to shrink beyond their content width */
    /* Optional: Add styles for spacing, alignment, etc. */
}

.buttondiv {
    height: 89.83px;
    width: 89.83px;
    margin-top: 266.53px;
}

.buttonright {
    height: 35px;
    width: 19px;
    margin-top: 26px;
    margin-left: 28px;
    background: url('../Images/Vector\ copy\ 3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.buttonleft {
    height: 35px;
    width: 19px;
    margin-top: 26px;
    margin-left: 28px;
    background: url('../Images/Vector\ copy\ 4.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.numberofimgs {
    height: 23px;
    width: 310px;
    margin-top: 50px;
    margin-left: 370px;
    display: flex;
    justify-content: center;
}

.eachimgcircle {
    height: 23px;
    width: 23px;
    border-radius: 50%;
    /* background-color: rgba(255, 249, 249, 1); */
    margin-left: 10px;
}

.yoga {
    width: 358px;
    height: 387px;
    margin-top: 144px;
    border-radius: 30px;
    background: url('../Images/yoga.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sports {
    width: 358px;
    height: 387px;
    margin-top: 144px;
    border-radius: 30px;
    background: url('../Images/Sports.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.shravan {
    width: 537px;
    height: 387px;
    border-radius: 30px;
    background-color: rgba(255, 228, 175, 1);
}

.shravanbackimg {
    width: 537px;
    height: 387px;
    border-radius: 30px;
    margin-left: 104px;
    margin-top: 2px;
    width: 328px;
    height: 382px;
    background: url('../Images/Vector\ \(2\).png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: auto;
}

.txt {
    margin-top: 138px;
    color: darkred;
    font-size: 48px;
    font-weight: bold;
    padding: 5px;
    /* font-family: Reem Kufi Fun; */
}

.mathamatics {
    width: 358px;
    height: 387px;
    border-radius: 30px;
    background: url('../Images/Group.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(242, 156, 144, 1);
}

.independence {
    width: 358px;
    height: 387px;
    border-radius: 30px;
    background: url('../Images/Component\ 11.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(255, 201, 201, 1);
}

.independencetext {
    font-size: 43px;
}

.gurupurnima {
    width: 358px;
    height: 387px;
    border-radius: 30px;
    background: url('../Images/Component\ 11\ copy.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(255, 214, 205, 1);
}

.multilingual {
    width: 358px;
    height: 387px;
    border-radius: 30px;
    background-color: aqua;
    background: url('../Images/Component\ 11\ copy\ 2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(255, 222, 214, 1);
}

.guruhar {
    width: 537px;
    height: 387px;
    border-radius: 30px;
    background: url('../Images/guru_harkrishan1\ 1.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(249, 131, 115, 1);
}

.guruhartext {
    width: 350px;
    margin-left: 15%;
}

.graduation {
    width: 537px;
    height: 387px;
    border-radius: 30px;
    background: url('../Images/Component\ 11\ copy\ 3.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(255, 201, 201, 1);
}

.graduationtext {
    width: 380px;
    margin-left: 15%;
}

.farewell {
    width: 751px;
    height: 387px;
    border-radius: 30px;
    background: url('../Images/Component\ 11\ copy\ 4.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(255, 228, 175, 1);
}

.ScrollingParent {
    margin-top: 162px;
    margin-left: 77px;
    width: 1361px;
    height: 853px;
    display: flex;
}

.DifferentImg {
    height: 853px;
    width: 995px;
    background-color: rgba(246, 221, 250, 1);
    border-radius: 72.47px;
    overflow: auto;
}

.cutScreen {
    height: 82.52px;
    width: 82.52px;
    margin-top: 30px;
    margin-left: 878px;
    background-color: rgba(236, 186, 186, 1);
    border-radius: 50%;
    overflow: auto;
}

.cutIcon {
    background: url('../Images/Vector\ copy\ 2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 26px;
    width: 26px;
    margin-left: 27px;
    margin-top: 27px;
}

.Scrollingmain {
    height: 816px;
    width: 347px;
    margin-left: 47px;
    margin-top: 8px;
    overflow-y: auto;
}

.Scrollingmaindivs {
    width: 315px;
    height: 168px;
    margin-bottom: 20px;
    border-radius: 29px;
    overflow: auto;
}

.showname {
    display: none;
}

.sidescrollnamediv {
    height: 58px;
    width: 223px;
    margin-top: 55px;
    margin-left: 48px;
    color: darkred;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}



/* @media only screen and (max-width: 1160px) {
    .GalleryMainImg {
        background: url('../Images/IMAGE.png');
        height: 529px;
        width: 1109px;
        margin-top: 52px;
        margin-left: 25px;
        border-radius: 29px;
    }

    .Galleryfirst {
        background: url('../Images/gallery2.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 570px;
        width: 447px;
        margin-top: 0px;
    }

    .mainimgdiv {
        width: 980px;
        height: 623px;
        display: flex;
    }

    .imgbackground {
        height: 623px;
        width: 815.35px;
        background-color: white;
        border-radius: 27px;
    }

    .GallerySecondSectionimages {
        height: 623px;
        width: 800.35px;
    }

    .GallerySecondSection {
        margin-top: 50px;
        margin-left: 27px;
        height: 1793px;
        width: 1088px;
    }

    .buttondiv {
        height: 89.83px;
        width: 89.83px;
        margin-top: 266.53px;
    }

    .buttonright {
        height: 35px;
        width: 19px;
        margin-top: 26px;
        margin-left: 28px;
        background: url('../Images/Vector\ copy\ 3.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .buttonleft {
        height: 35px;
        width: 19px;
        margin-top: 26px;
        margin-left: 28px;
        background: url('../Images/Vector\ copy\ 4.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .numberofimgs {
        height: 23px;
        width: 310px;
        margin-top: 50px;
        margin-left: 370px;
        display: flex;
        justify-content: center;
    }

    .eachimgcircle {
        height: 23px;
        width: 23px;
        border-radius: 50%;
        margin-left: 10px;
    }

    .yoga {
        width: 285px;
        height: 387px;
        margin-top: 144px;
        border-radius: 30px;
    }

    .sports {
        width: 316px;
        height: 387px;
        margin-top: 144px;
        border-radius: 30px;
        background: url('../Images/Sports.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .shravan {
        width: 475px;
        height: 387px;
        border-radius: 30px;
        background-color: rgba(255, 228, 175, 1);
    }

    .shravanbackimg {
        width: 475px;
        height: 387px;
        border-radius: 30px;
        margin-left: 104px;
        margin-top: 2px;
        width: 328px;
        height: 382px;
        background: url('../Images/Vector\ \(2\).png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: auto;
    }

    .txt {
        margin-top: 138px;
        color: darkred;
        font-size: 36px;
        font-weight: bold;
        padding: 5px;
    }

    .mathamatics {
        width: 285px;
        height: 387px;
        border-radius: 30px;
        background: url('../Images/Group.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(242, 156, 144, 1);
    }

    .independence {
        width: 285px;
        height: 387px;
        border-radius: 30px;
        background: url('../Images/Component\ 11.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(255, 201, 201, 1);
    }

    .independencetext {
        font-size: 36px;
    }

    .gurupurnima {
        width: 285px;
        height: 387px;
        border-radius: 30px;
        background: url('../Images/Component\ 11\ copy.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(255, 214, 205, 1);
    }

    .multilingual {
        width: 285px;
        height: 387px;
        border-radius: 30px;
        background-color: aqua;
        background: url('../Images/Component\ 11\ copy\ 2.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(255, 222, 214, 1);
    }

    .guruhar {
        width: 475px;
        height: 387px;
        border-radius: 30px;
        background: url('../Images/guru_harkrishan1\ 1.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(249, 131, 115, 1);
    }

    .guruhartext {
        width: 285px;
        margin-left: 15%;
    }

    .graduation {
        width: 475px;
        height: 387px;
        border-radius: 30px;
        background: url('../Images/Component\ 11\ copy\ 3.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(255, 201, 201, 1);
    }

    .graduationtext {
        width: 285px;
        margin-left: 15%;
    }

    .farewell {
        width: 600px;
        height: 387px;
        border-radius: 30px;
        background: url('../Images/Component\ 11\ copy\ 4.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(255, 228, 175, 1);
    }

    .ScrollingParent {
        margin-top: 162px;
        margin-left: 77px;
        width: 1361px;
        height: 853px;
        display: flex;
    }

    .DifferentImg {
        height: 853px;
        width: 995px;
        background-color: rgba(246, 221, 250, 1);
        border-radius: 72.47px;
        overflow: auto;
    }

    .cutScreen {
        height: 82.52px;
        width: 82.52px;
        margin-top: 30px;
        margin-left: 878px;
        background-color: rgba(236, 186, 186, 1);
        border-radius: 50%;
        overflow: auto;
    }

    .cutIcon {
        background: url('../Images/Vector\ copy\ 2.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 26px;
        width: 26px;
        margin-left: 27px;
        margin-top: 27px;
    }

    .Scrollingmain {
        height: 816px;
        width: 347px;
        margin-left: 47px;
        margin-top: 8px;
        overflow-y: auto;
    }

    .Scrollingmaindivs {
        width: 315px;
        height: 168px;
        margin-bottom: 20px;
        border-radius: 29px;
        overflow: auto;
    }

    .showname {
        display: none;
    }

    .sidescrollnamediv {
        height: 58px;
        width: 223px;
        margin-top: 55px;
        margin-left: 48px;
        color: darkred;
        font-size: 26px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }

} */

/* Responsive styles for smaller screens (e.g., mobile devices) */
@media only screen and (max-width: 768px) {

    .GalleryMainImg {
        background: url('../Images/IMAGE.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 149px;
        width: 371px;
        margin-top: 17px;
        margin-left: 9px;
        overflow: auto;
    }

    .GallerySecondSection {
        margin-top: 67px;
        margin-left: 3px;
        height: 475px;
        width: 381px;
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
    }

    .Galleryfirst {
        display: none;
    }

    .yoga {
        height: 112px;
        width: 182px;
        margin-top: 0px;
        margin-left: 0px;
        border-radius: 12px;
        margin-bottom: 10px;
    }

    .sports {
        width: 182px;
        height: 112px;
        margin-top: 0px;
        border-radius: 12px;
    }

    .shravan {
        width: 155px;
        height: 112px;
        border-radius: 12px;
        margin-bottom: 10px;
    }

    .shravanbackimg {
        width: 95px;
        height: 110px;
        border-radius: 30px;
        margin-left: 30px;
        margin-top: 1px;
        border-radius: 12px;
    }

    .mathamatics {
        width: 103px;
        height: 112px;
        border-radius: 12px;
    }

    .independence {
        width: 103px;
        height: 112px;
        border-radius: 12px;
    }

    .txt {
        margin-top: 38.95px;
        color: darkred;
        font-size: 12px;
        font-weight: bold;
        padding: 2px;
        text-align: center;
        /* font-family: Reem Kufi Fun; */
    }

    .gurupurnima {
        width: 103px;
        height: 112px;
        border-radius: 12px;
    }

    .multilingual {
        width: 103px;
        height: 112px;
        border-radius: 12px;
    }

    .guruhar {
        width: 155px;
        height: 112px;
        border-radius: 12px;
        margin-bottom: 10px;
    }

    .guruhartext {
        width: 101px;
    }

    .graduation {
        width: 155px;
        height: 112px;
        border-radius: 12px;
    }

    .graduationtext {
        width: 110px;
        font-size: 13px;
    }

    .farewell {
        width: 208px;
        height: 112px;
        border-radius: 12px;
    }

    /* removing sidscrolling */
    .Scrollingmain {
        display: none;
    }

    .ScrollingParent {
        height: 349px;
        width: 378px;
        margin-top: 17px;
        margin-left: 4px;
        overflow: auto;
    }

    .DifferentImg {
        height: 349px;
        width: 406px;
        overflow: auto;
        border-radius: 29px;
        /* margin-top: 51px; */
    }

    .mainimgdiv {
        height: 254px;
        width: 380px;
    }

    .cutScreen {
        height: 33px;
        width: 33px;
        margin-left: 47px;
        margin-top: 12px;
    }

    .cutIcon {
        height: 15px;
        width: 15px;
        margin-left: 10px;
        margin-top: 10px;

    }

    .GallerySecondSectionimages {
        height: 254px;
        width: 332px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .imgbackground {
        height: 254px;
        width: 332px;
        border-radius: 10px;
    }

    .GallerySecondSectionimages {
        height: 254px;
        width: 332px;
        margin-top: 0px;
    }

    .buttondiv {
        height: 36.67px;
        width: 36.67px;
        margin-top: 120.41px;
    }

    .buttonright {
        height: 20px;
        width: 14px;
        margin-top: 10px;
        margin-left: 13px;
    }

    .buttonleft {
        height: 20px;
        width: 14px;
        margin-top: 10px;
        margin-left: 13px;
    }

    .numberofimgs {
        height: 23px;
        width: 200px;
        margin-top: 15px;
        margin-left: 85px;
        display: flex;
        justify-content: center;
    }

    .eachimgcircle {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        /* background-color: rgba(255, 249, 249, 1); */
        margin-left: 10px;
    }

    .containcutshowname {
        display: inline-flex;
    }


    .showname {
        display: inline;
        height: 30px;
        width: 178px;
        color: darkred;
        margin-top: 18px;
        margin-left: 112px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }

}