.mainImage {
  margin-top: 3%;
  margin-bottom: 3%;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.navLifeGHK {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.navLifeGHK h2 {
  width: 15%;
  height: auto;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 0.5%;
  transition: background-color 0.5s ease, border-radius 0.5s ease;
  cursor: pointer;
  text-align: center;
}

.navLifeGHK h2:hover {
  background-color: #ffb3b1;
  border-radius: 20px;
}

.LifeGHKDescription {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  border-radius: 20px;
  margin-bottom: 5%;
}

.container {
  display: flex;
  flex-direction: column;
}

.innerContainer {
  display: flex;
  flex-direction: row;
}

.imgContainer {
  background-color: #ffd1cb;
  padding: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin: 2%;
}

.imgContainer h1{
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: 6%;
}

/* button
{
  background-color: #CD5C5C;
  padding: 1.5%;
  border-radius: 20px;
  margin-top: 6%;
} */
@media screen and (max-width:650px)
{
  .navLifeGHK
  {
    width:80%;
    height:auto;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    gap:10%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .navLifeGHK h2 {
    width: 1%;
    height: auto;
    height: auto;
    font-size:x-small
  }
  
}
