.App {
  text-align: center;
}

.container{
  /* height: 5477px;
  width: 1500px; */
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

/*header {
  background-color: lightblue;
  padding: 20px;
}*/

/* .content-wrapper {
  margin-top: 162px;

} */

.infrastructureContent {
  width: 90vw;
  height: auto;
  display: flex;
  gap: 64px;
  justify-content: center;
  flex-direction: row;
}

.section1 {
  
  display: flex;
  flex-direction: column;
  width:100%;
  height: auto;
  gap: 64px;
  margin-left: 2%;
}

.section2 {
  
  display: flex;
  flex-direction: column;
  width:100%;
  height: auto;
  gap: 64px;
  margin-left: 2%;
}

.sub-section2{
  background-color: #ffdede;
  height: 597px;
  width: 640px;
  border-radius: 50px;
}

.sub-section1 {
  background-color: #ffdede;
  height: 597px;
  width: 640px;
  border-radius: 50px;
}

.infrastructurDivImage{
  height: 483px;
  width: 602px;
  border-radius: 50px;
  margin-top: 20px;
  display: inline;
  margin-left: 20px;
}

.name{
  color: darkred;
  font-size: 36px;
  margin-top: 20px;
  text-align: center;

}

/*footer {
  background-color: lightblue;
  padding: 20px;
}*/

@media screen and (max-width:650px)
{
  .infrastructureContent
  {
    display:flex;
    flex-direction: column;
    /* margin-bottom:100% */
  }
  .sub-section1
  {
    width:60%;
    height:auto;
    margin-left:10%;
  }
  .sub-section2
  {
    width:60%;
    height:auto;
    margin-left:10%;
  }
  .infrastructurDivImage
  {
    width:90%;
    height:auto
  }
  .name
  {
    font-size:20px
  }
  .section1
  {
    width:120%;
    height: auto;
  }
  .section2
  {
    width:120%;
    height: auto;
  }
  .sub-section1 img
  {
    margin-left:5%
  }
  .sub-section2 img
  {
    margin-left:5%
  }
}