.academicResultImage{
    max-width: 1150px;
    width: 100%;
    display: flex;
    margin: 40px auto 120px; 
    position: relative; 
}

.academicResultImage img{
    max-width: 1150px;
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.TextAcademicResult{
    position: absolute;
    top: 51%; /* Adjust as needed */
  left: 32%; /* Adjust as needed */
    max-width:749px;
    width: 100%; 
  transform: translate(-50%, -50%);
    text-align: center;
    color: #8C1F1F;
    font-size: 74px;
    font-family: 'Reem Kufi Fun';
    font-weight: 700;
    text-transform: uppercase;
    word-wrap: break-word;
}

.CollegeSchool{
    max-width: 1100px;
    display: flex;
    margin: 80px auto;  
    justify-content: space-evenly;
}

.resultSchoolImage{
    max-width: 500px;
    width: 100%;
    height: auto;

}

.collegeImage{
    max-width: 500px;
    width: 100%;
    height: auto;
}

.DivisionsSTD{
    max-width: 1100px;
    display: flex;
    margin: 60px auto;  
    justify-content: space-evenly;
    align-items: stretch;
}

.allImage{
    max-width: 250px;
    width: 100%;
    height: auto;
}

.ElthImage{
    max-width: 250px;
    width: 100%;
    height: auto;
}

.TwthImage{
    max-width: 250px;
    width: 100%;
    height: auto;
}

.SearchAddNew{
    max-width: 1150px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 80px auto;
}

.Header1searchbar {
    /* width: 332px;
    height: 46px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 15px;
    background: linear-gradient(180deg, #FAE8E8 0%, rgba(255, 203, 203, 0.59) 100%);
}

.Header1search {
    width: 270px;
    height: 42px;
    border: none;
    background: linear-gradient(180deg, #FAE8E8 0%, rgba(255, 203, 203, 0.59) 100%);
    border-radius: 15px;
    color: rgba(0, 0, 0, 0.70);
    text-align: left;
    padding-left: 10px;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;

}

.Searchicon {
    padding: 1.042px 7.044px 1.042px 1.042px;
    /* width: 17.915px;
    height: 17.917px; */
    width: 35px;
    height: 30px;

}

.AddNewBtn{
    max-width: 130px;
    width: 100%;
    height: auto;
}

.tableResult{
    max-width: 1150px ;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px auto;
}

.ResultTable{
    max-width: 1150px ;
    width: 100%;
    border-color: #FFFCFC;
}

.roundedTableWrapper {
    max-width: 1150px ;
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
  }

.ResultTable th{
    color: #8C1F1F;
    font-size: 26px;
    font-family: 'Reem Kufi Fun';
    font-weight: 600;
    text-transform: uppercase;
    background-color: #FBD9D9;
    line-height: 50.36px;
    letter-spacing: 0.48;
}

.ResultTable td{
    color: #3B0D0D;
    font-size: 19px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 31.36px;
    letter-spacing: 0.48;
}


.ResultTable .oddRow td{
    background-color: #FFF3F3;
}

.ResultTable .evenRow {
    background-color: #FFFCFC;
}
  
.borderApplied {
    border: 4px solid #B85E54;
    border-radius: 37px;
}


.borderApplied1 {
    border: 4px solid #cda148;
    border-radius: 37px;
}

.borderApplied2 {
    border: 4px solid #B85E54;
    border-radius: 37px;
}

