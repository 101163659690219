

.ruleimgmain {
  background-image: url('../../Images/top\ copy.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 613px;
  /* width: 1429px; */
  width: 90vw;
  /* margin-top: 52px;
  margin-left: 44px; */
  flex-shrink: 1;
  margin-left: 5vw;
  margin-right: 5vw;
}

.rulemain {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  margin-left: 5%;
  margin-right:5%;
  height: auto;
  flex-shrink: 1;
}

.rulemainbar {
  width:100%;
  height:auto;
  padding:0.5%;
  text-align:center;
}
.rulemainbar:hover
{
  background-color: #ffb3b1;
  border-radius: 20px;
}

.rulelink {
  text-decoration: none;
  color: rgba(140, 31, 31, 1);
}


/* General */

.rulecomponents1 {
  /* width: 1393px; */
  width: 95%;
  height: auto;
  margin-left: 2.5%;
  margin-right:2.5%;
  margin-top: 21px;
  margin-bottom: 100px;
  border-radius: 40px;
  overflow: auto;
  background-image: url('../../Images/genaral2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 1;
}


.rulecomponents2 {
  /* width: 1393px; */
  width: 95%;
  height: auto;
  margin-left: 2.5%;
  margin-right:2.5%;
  margin-top: 21px;
  margin-bottom: 100px;
  border-radius: 40px;
  overflow: auto;
  background-image: url('../../Images/Screenshot\ \(96\)\ copy.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 1;
}

.rulecomponents3{
  /* width: 1393px; */
  width: 95%;
  height: auto;
  margin-left: 2.5%;
  margin-right:2.5%;
  margin-top: 21px;
  margin-bottom: 100px;
  border-radius: 40px;
  overflow: auto;
  background-image: url('../../Images/relues\ in\ con2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 1;
}

.rulecomponents4{
  /* width: 1393px; */
 
  width: 95%;
  height: auto;
  margin-left: 2.5%;
  margin-right:2.5%;
  margin-top: 21px;
  
  margin-bottom: 100px;
  border-radius: 40px;
  overflow: auto;
  background-image: url('../../Images/attendence2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 1;
}

.rulecomponents5{
  /* width: 1393px; */
  width: 95%;
  height: auto;
  margin-left: 2.5%;
  margin-right:2.5%;
  margin-top: 21px;
  
  margin-bottom: 100px;
  border-radius: 40px;
  overflow: auto;
  background-image: url('../../Images/rules\ and\ reg2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 1;
}

.rulecomponents6{
  /* width: 1393px; */
  width: 95%;
  height: auto;
  margin-left: 2.5%;
  margin-right:2.5%;
  margin-top: 21px;
  margin-bottom: 100px;
  border-radius: 40px;
  overflow: auto;
  background-image: url('../../Images/withdraw2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 1;
}

/* .rulecomponents1img {
  background-image: url('../../Images/flat-color-icons_rules.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 712px;
  width: 712px;
  position: relative;
  top: 105px;
  left: 340px;
} */


.rulecomponents1text{
  height: 506.01px;
  /* width: 1277px; */
  width: 100vw;
  margin-top: 68px;
  margin-left: 56px;
  display:flex;
  flex-direction: column;
}

.component1list{
  display: flex;
  margin-left: -40px;
  margin-top: 10px;
  /* width: 1275px; */
  width: 100vw;
}

.componentbullet{
  background: url('../../Images/Default.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
}

.componentlistdata{
  color: rgba(40, 5, 5, 1);
  width:90%;
  height:auto;
  margin-left: 5%;
  margin-right:5%;
  text-align: start;
  margin-top: 0px;
  font-family: Poppins;
  font-size: 24px;
}

/* inside text  */

.insidetextcmp1{
  margin-top: 1%;
}

.liststextinsidecmp1{
  /* width: 1185px; */
  width:100vw;
  height: 25px;
  margin-left: 10px;
  text-align: start;
  display: flex;
  align-items: flex-start;
}

.bulletuper{
  height: 18px;
  width: 20px;
  background: url('../../Images/Vector\ copy.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 0px;
}

.listdatainsidecmp1{
  margin-left: 24px;
  font-family: Poppins;
  font-size: 20px;
  margin-top: 0px;
  letter-spacing: 0.07em;
}

@media screen and (max-width:650px) {
  .rulecomponents1,
  .rulecomponents2,
  .rulecomponents3,
  .rulecomponents4,
  .rulecomponents5,
  .rulecomponents6
  {
    width: 90%;
    margin-left:5%;
    margin-right:5%;
    height: auto;
    overflow-wrap: break-word;
  }
  .rulecomponents1text
  {
    width:100%;
    height:auto;
    overflow-wrap: break-word;
  }
  .component1list
  {
    width:100%;
    height:auto;
    overflow-wrap: break-word;
  }
  .componentlistdata
  {
    width:100%;
    height:auto;
   
    overflow-wrap: break-word;
  }
  .rulemainbar {
    width:50%;
    height:auto;
    margin: 1%;
    font-size:smaller;
    text-align:center;
  }
  .ruleimgmain
  {
    width:90%;
    height:200px;
    margin-left:5%;
    margin-right: 5%;
    margin-bottom: 5%;
  }
  .liststextinsidecmp1
  {
    width:80%;
    height:auto
  }
}