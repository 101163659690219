.contact-info-container {
    max-width: 491px;
    height: 215px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ffeee4;
    border-radius: 5px;
    background-color: #FFEEE4;
    flex-direction: column;
    align-items: center;
    gap: 30.215px;

}
  
.location,.address,.telephone{
  color: #A88383;
  font-family: Poppins;
  font-size: 14.107px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.354px; /* 144.286% */
  letter-spacing: 0.282px;
}

/* Style for each info section */
.location,
.telephone,
.address {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 30px;
}

/* Styling for the icons */
.location svg,
.telephone svg,
.address svg {
  margin-right: 10px; /* Add some right margin to separate icon and text */
}

/* Styling for the text */
.location span,
.telephone span,
.address span {
  font-size: 16px; /* Adjust the font size as needed */
  color: #333; /* Change the color of the text as needed */
}
