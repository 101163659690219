.councellingmainimg{
    background: url("../Images/Mask\ group.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 1408px;
    Height: 615px;
    margin-left: 52px;
    margin-left: 44px;  
    overflow: auto;
}

.Cimginnertext{
    margin-top: 186px;
    margin-left: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: darkred;
    width: 749px;
    height: 222px;
    font-size: 44px;
}

.guidlines{
    /* width: 1410px; */
    /* Height: 611px; */
    margin-left: 89px;
    margin-top: 162px;
    display:flex;
}
.guidlinesfirst{
    height: 542px;
    width: 626px;
}

pre {
    font-family: poppins;
  }

.guidlinestext{
    text-align: start;
    color: rgba(40, 5, 5, 1);
    height: 245px;
    font-size: 28px;
    font-family: poppins;
}

.guidlinestext2{
    text-align: start;
    color: rgba(40, 5, 5, 1);
    height: 275px;
    width: 525px;
    margin-left: 0px;
    margin-top: 10px;
}
.liststext{
    display: flex;
    margin-left: -40px;
    margin-top: 4px;
}
.bullet{
    background: url('../Images/Default.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 24px;
    width: 26px;
    margin: 1px;
}

.listdata{
    margin-left: 8px;
    color: rgba(40, 5, 5, 1);
    height: auto;
    width: 545px;
    font-size: 21px;
}

.guidlinesimg{
    background: url('../Images/PIC.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 125px;
    height: 611px;
    width: 660px;
}

.group10{
    height: 986px;
    width: 1322px;
    margin-top: 162px;
    margin-left: 89px;
    margin-bottom: 150px;
}

.group10mainheading{
    width: 1315px;
    margin-left: 3px;
    height: 72px;
    color: darkred;
    font-size: 25px;
}

.rectangle42{
    height: 824px;
    width: 1322px;
    margin-top: 90px;
    margin-left: 0px;
    background-color: rgb(250 228 232);
    border-radius: 30px;
    overflow: auto;
}

.group14{
    height: 764.99px;
    width: 1221.46px;
    margin-top: 30px;
    margin-left: 42.02px;
    background: url('../Images/Group\ 14.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: auto;
}

.frame55{
    margin-top: 61px;
    margin-left: 20px;
    height: 637px;
    width: 1137px;
}

.subframe55{
    width: 1186px;
    height: 637px;
}

.liststextframe55{
    display: flex;
    margin-left: -40px;
}

.listdataframe55{
    margin-left: 20px;
    margin-top: 0px;
    color: rgba(40, 5, 5, 1);
    height: auto;
    text-align: start;
    font-size: 27px;
}

@media only screen and (max-width: 768px) {
    .councellingmainimg{
        width: 402px;
        Height: 175.65px;
        margin-left: 16px; 
        overflow: auto;
        margin-top: 44px;
    }
    
    .Cimginnertext{
        margin-top: 50px;
        margin-left: 14px;
        width: 214px;
        height: 66px;
        font-size: 14px;
    }
    
    .guidlines{
        /* width: 1410px; */
        /* Height: 611px; */
        margin-left: 89px;
        margin-top: 162px;
        display:flex;
        flex-direction: column-reverse;
    }
    .guidlinesfirst{
        height: 226px;
        width: 364px;
        margin-top: 190px;
        margin-left: -59px;
    }
    
    pre {
        font-family: poppins;
      }
    
    .guidlinestext{
        height: 190px;
        font-size: 13px;
        margin-top: -170px;
        width: 383px;
    }
    
    .guidlinestext2{
        width: 364px;
        font-size: 12px;
        font-family: SemiBold;
        margin-left: 0px;
        margin-top: 10px;
    }
    
    /* .guidlist{
        font-size: 12px;
    } */
    .liststext{
        display: flex;
        margin-left: -2px;
        margin-top: 4px;
    }
    .bullet{
        /* background: url('../Images/Default.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; */
        height: 23px;
        width: 26px;
        margin: 1px;
    }
    
    .listdata{
        margin-left: 8px;
        color: rgba(40, 5, 5, 1);
        height: auto;
        width: 545px;
        font-size: 19px;
    }
    
    .guidlinesimg{
        background: url('../Images/PIC.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: -136px;
        margin-left: -71px;
        height: 250px;
        width: 390px;
    }
    
    .group10{
        height: 455px;
        width: 390px;
        margin-top: 230px;
        margin-left: 16px;
        margin-bottom: 70px;
    }
    
    .group10mainheading{
        width: 350px;
        margin-left: 3px;
        height: 58px;
        color: darkred;
        font-size: 18px;text-align: center;
        font-family: SemiBold;
    }
    
    .rectangle42{
        height: 355px;
        width: 390px;
        margin-top: 54px;
        margin-left: 0px;
        background-color: rgb(250 228 232);
        border-radius: 15px;
        overflow: auto;
    }
    
    .group14{
        height: 355px;
        width: 390px;
        margin-top: 0px;
        margin-left: 10px;
        
    }
    
    .frame55{
        margin-top: 40px;
        margin-left: 15px; 
        height: 233px;
        width: 352px;
        /* height: 355px;
        width: 390px; */
        /* margin-top: 0px;
        margin-left: 0px; */
    }
    
    .bullet2{
        background: url('../Images/Default.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 10px;
        width: 10px;
        margin: 1px;
    }
    .subframe55{
        height: 233px;
        width: 345px;
        /* height: 355px;
        width: 390px; */
        margin-top: 0px;
        margin-left: 0px;
    }
    
    .liststextframe55{
        display: flex;
        margin-left: 0px;
    }
    
    .listdataframe55{
        margin-left: 12px;
        font-size: 8px;
    }
    
  }