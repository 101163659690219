Header {
    display: flex;
    margin: 53px 87.5px 30px 86.5px;
}

.Header1 {
    /* width: 1326px; */
    width: 100vw;
    height: 94px;
    display: inline-flex;
    padding: 0px 18.5px 0px 17.5px;
    justify-content: space-around;
    align-items: center;
    margin: 58px 0px 19px;
}

.logoimage {
    width: 75px;
    height: 100px;
    background: url('./Images/b7ucsp1i1.png'), lightgray 50% / cover no-repeat;
}

.Header1txt {
    width: 470px;
    height: 90px;
    color: #3B328C;
    text-align: center;
    font-family: Roboto;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.Header1searchbar {
    /* width: 332px;
    height: 46px; */
    display: flex;
    margin-left: 287px;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 15px;
    background: linear-gradient(180deg, #FAE8E8 0%, rgba(255, 203, 203, 0.59) 100%);
}

.Header1search {
    width: 310px;
    height: 46px;
    border: none;
    background: linear-gradient(180deg, #FAE8E8 0%, rgba(255, 203, 203, 0.59) 100%);
    border-radius: 15px;
    color: rgba(0, 0, 0, 0.70);
    text-align: left;
    padding-left: 10px;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;

}

.Searchicon {
    padding: 1.042px 7.044px 1.042px 1.042px;
    /* width: 17.915px;
    height: 17.917px; */
    width: 20px;
    height: 20px;

}

.Header1contactdiv {
    margin-left: 32px;
    padding: 16px;
    height: 46px;
    width: 99px;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 14px;
    text-wrap: nowrap;
    color: white;
    font-size: medium;
    font-weight: 700;
    border-radius: 28px;
    background: var(--button-radail, #D63A3A);
    cursor: pointer;
}

.Header2 {
    /* position: absolute;
    /* top: 50%; */
    /* left: 104px;  */
    /* margin: 0px 104px 0px 160px; */
    margin: 40px 50px 40px 65px;
    display: flex;
    /* width: 1236px; */
    width: 90vw;
    padding: 0 50px;
    height: 62px;
    flex-shrink: 1;
    overflow: visible;
    /* overflow-x: auto; */
    border-radius: 22px;
    background: radial-gradient(50% 50% at 50% 50%, #DADCF4 0%, rgba(230, 232, 255, 0.98) 38.02%, #E8EAFF 69.27%, #E9EAFF 100%);

}

.Header2Nav {
    height: auto;
    width: auto;
    margin-right: 50px;
    display: flex;
    padding: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: #3B328C;
    text-align: center;
    -webkit-text-stroke-width: 0.20000000298023224;
    -webkit-text-stroke-color: #3B328C;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.navlink {
    text-decoration: none;
    /* Remove default underline */
}

.active , a:hover {
    background: url('./Images/Rectangle\ 37.png');
    fill: linear-gradient(0deg, rgba(23, 11, 94, 0.15) -7.41%, rgba(217, 217, 217, 0.00) 109.26%);
    /* filter: blur(1.149999976158142px); */
    /* text-decoration: underline; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.Arrow {
    background: url('./Images/Vector\ \(1\).png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 5px;
    width: 8px;
}


/* nested navbar */

.dropdown {
    display: flex;
}

.dropdown-content {
    margin-top: 20px;
    position: absolute;
    color: #3B328C;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    background: radial-gradient(50% 50% at 50% 50%, #DADCF4 0%, rgba(230, 232, 255, 0.98) 38.02%, #E8EAFF 69.27%, #E9EAFF 100%);
}

.dropdown-content .nav-link {
    display: block;
    padding: 12px 16px;
    display: block;
    text-decoration: none;
}

.dropdown-content a:hover {
    background-color: #aea6fa;
}
.menuImg{
    display: none;

}
    @media only screen and (max-width: 600px) {
    .Header2,.Header1contactdiv{
        display: none;
    }
    .Header1 .Header1searchbar{
        display: none;
    }
    .Header1searchbar .Searchicon{
        display: none;
    }
    .Header1searchbar input{
        display: none;
    }
    .Header1{
        justify-content: space-between;
    }
    .Header1txt{
        /* width: 300px;
        font-size: medium;
        padding-top:18px ; */
        width: 250px;
    font-size: 12px;
    padding-top: 27px;
    padding-left: 0px;
    margin-left: -18px;
    }
    .Header1 > div > img{
        width: 40px;
        /* height: 80px; */
    }
    .menuImg{
        display: block;

    }
}

