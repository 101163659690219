.mainImage {
    margin-top: 3%;
    margin-bottom: 3%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .navLifeGHK {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .navLifeGHK h2 {
    width: 15%;
    height: auto;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    padding: 0.5%;
    transition: background-color 0.5s ease, border-radius 0.5s ease;
    cursor: pointer;
    text-align: center;
  }
  
  .navLifeGHK h2:hover {
    background-color: #ffb3b1;
    border-radius: 20px;
  }
  
  .LifeGHKDescription {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    border-radius: 20px;
    margin-bottom: 5%;
  }
  
  .newsContainer {
    display: flex;
    flex-direction: column;
  }
  
  .innerContainer {
    display: flex;
    flex-direction: row;
  }
  
  .imgContainer {
    background-color: #ffd1cb;
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    margin: 2%;
  }
  
  .imgContainer h1{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-top: 6%;
  }
  
  .newsButton
  {
    background-color: #CD5C5C;
    padding: 1.5%;
    border-radius: 20px;
    margin-top: 6%;
  }
  .newsImg
{
  height: 60vh;
  width:60%;
  margin-left:20%;
  margin-right: 20%;
}
.NewsPopUp{
  /* visibility: hidden; */
    /* width: 1085px;
    height: 550px; */
    background: rgba(217, 217, 217, 0.33); 
    color: #1e1b1b;
    box-shadow: 18px 17px 107.19999694824219px 26px #FEDADA inset;
    /* text-align: justify; */
    border-radius: 6px;
    /* padding: 80px 20px; */
    /* position: absolute; */
    /* z-index: 2;
    bottom: 105%;
    left: 40%; */
    backdrop-filter: blur(95.80px);
    /* margin-left: -200px;
    border-radius: 94px; */
  }